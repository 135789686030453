@import './../../styles/variables.scss';

.card-container {
    background-color: #FCFCFC;
    @include box-shadow;
    max-width: 60vw;
    padding: 15px;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}