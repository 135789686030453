@import './../../styles/variables.scss';

.page-not-found-container {
    button {
        @include button($dark-button-text-color, $button-backdrop-color);
        margin-top: 2rem;

        a {
            text-decoration: none;
            color: #000;
        }
    }
    
}