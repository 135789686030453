@import './../../../styles/variables.scss';

.pet-parent-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .pet-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
    }

    .loading-message-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }
}