@import './../../styles/variables.scss';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 2rem 1.4rem 2rem;
    background-color: white;
    border-bottom: 1px solid black;

    .button-sign-in,
    .button-sign-up,
    .button-pets-list {
        @include button($dark-button-text-color, $button-backdrop-color);
        width: 15%;
        margin-right: 0.5rem;
    }

    .navigation-link-text {
        text-decoration: none;
        font-weight: bold;
        color: $light-text-color;
        padding: 7px;
        padding-left: 28px;
        padding-right: 28px;
    }

    .webpage-name {
        flex: 1;
        padding-top: 0.7rem;
        font-weight: bold;
        color: $dark-text-color;
        font-size: 22px;
    }
}