@import './../../styles/variables.scss';

.landing-page-container {
    max-width: 1140px;
    margin: 0 auto;

    .landing-page-intro-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .blood-test-image {
            margin-top: 2rem;
            max-width: 50%;
        }

        .intro-message {
            text-align: center;
            margin: 2rem;

            .landing-page-message-text {
                font-size: 1.2rem;
                line-height: 1.5rem;
                color: $light-text-color;
            }
        }
    }

    .landing-page-gentleman-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;

        .gentleman-image {
            max-width: 100%;
        }

        .landing-page-gentleman-message {
            text-align: center;
            margin: 2rem;

            .landing-page-gentleman-message-text {
                font-size: 1.2rem;
                line-height: 1.5rem;
                color: $light-text-color;
            }
        }
    }

    .gallery-image-container {
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 5vh;
        justify-content: center;
        margin: 2rem 0;

        .gallery-image {
            @include box-shadow;
            margin-top: 2vh;
            height: 250px;
            width: 325px;
            margin-right: 20px;
            object-fit: cover;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    h2 {
        text-align: center;
        margin-top: 2rem;
        font-size: 30px;
        color: $light-text-color;
    }

    p {
        text-align: center;
        margin-bottom: 2rem;
        color: $light-text-color;
    }
}