@import './../../styles/variables.scss';

.sign-up-container {
    width: 100%;

    h1,
    label {
        color: $dark-text-color;
    }

    label {
        padding-left: 0.5rem;
    }

    .error-messages-container {
        width: 80%;
        margin: auto;
        padding-bottom: 1rem;

        h3 {
            color: red;
        }

        ul {
            color: $dark-text-color;
        }
    }

    .sign-up-text {
        text-align: center;
    }

    .label-text {
        font-weight: bold;
        margin-left: -5px;

        .input-field {
            width: 40vw;
            padding-top: 1vh;
            padding-bottom: 1vh;
            margin-bottom: 2vh;
        }
    }

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .button-container {
            width: 40.5vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2rem;
            margin-bottom: 2rem;

            .create-account-button {
                @include button($dark-button-text-color, $button-backdrop-color);
            }

            .home-link,
            .sign-in-link {
                font-weight: bold;
                border: none;
                margin-top: 1rem;
                ;
                color: $dark-text-color;

                a {
                    @include link-text;
                }
            }
        }
    }
}