$backdrop-gradient-color: linear-gradient(to bottom left, #57DAD5, #5D33B4);
// $button-gradient-color: linear-gradient(to bottom right, #5D33B4, #57DAD5);
$button-backdrop-color: #69d3cf;
$backdrop-color: #5e9ca1;
$primary-color: #B9D4D6;
$border-color: #ab75bd;
$dark-text-color: #000;
$light-text-color: #fff;
$light-button-text-color: #57DAD5;
$dark-button-text-color: #000;
$light-text-highlight-color: #288582;
$dark-text-highlight-color: #321782;


@mixin breakpoint($max-width) {
    @media only screen and (max-width: $max-width) {
        @content;
    }
}

@mixin medium-breakpoint() {
    @include breakpoint(1060px) {
        @content;
    }
}

@mixin button($text-color, $background-color) {
    font-weight: bold;
    width: 100%;
    padding: 0.8rem 0 0.8rem 0;
    border: 1px solid black;
    color: $text-color;
    background-color: $background-color;
    @content;
}

@mixin box-shadow {
    box-shadow: 2px 2px 2px 0px gray;
    @content;
}

@mixin link-text {
    text-decoration: none;
    color: $light-text-highlight-color;
    font-weight: bold;
    @content;
}