@import './../../styles/variables.scss';

.testimonial-container {
    display: flex;
    flex-direction: column;

    h1 {
        text-align: center;
    }

    .testimonial-home-link {
        &::before {
            content: '<';
            margin-right: 0.5rem;
            font-weight: bold;
            color: $light-text-highlight-color;
        }

        a {
            @include link-text;
        }
    }
}