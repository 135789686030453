@import './../../styles/variables.scss';

.modal-container-add-entry {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
        width: 500px;
        background-color: #fff;
        padding: 1rem;
    }

    .modal-header,
    .modal-footer {
        padding: 10px;
    }

    .modal-title {
        margin: 0;
    }

    .modal-body {
        padding: 10px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }

    .modal-button-container {
        padding: 2rem 0 0 0;

        .modal-button {
            @include button($dark-button-text-color, $button-backdrop-color);
            margin-bottom: 0.5rem;
        }
    }

    .modal-image-container {
        img {
            margin-top: 1rem;
        }
    }

    .modal-sugar-concentration-input,
    .modal-sugar-concentration-measured {
        border-bottom: 1px solid #eee;
        padding: 1rem 0 1rem 0;

        input {
            width: 85%;
            padding: 0.3rem 0 0.3rem 0;
        }

        select {
            width: 100%;
            padding: 0.3rem 0 0.3rem 0;
        }
    }

    .date-time-container {
        display: flex;
        justify-content: space-between;
        width: 88%;

        .modal-sugar-concentration-date {
            padding: 1rem 0 1rem 0;

            input {
                width: 100%;
                padding: 0.3rem 0 0.3rem 0;
            }
        }

        .modal-sugar-concentration-time {
            padding: 1rem 0 1rem 0;

            input {
                width: 150%;
                padding: 0.3rem 0 0.3rem 0;
            }
        }

    }

    .modal-error-messages {
        color: red;
        font-weight: bold;
    }

    .modal-success-message {
        color: green;
        font-weight: bold;
    }
}