@import './../../styles/variables.scss';

.sticky-button-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 60px;
    width: 100%;
    
    .sticky-button {
        @include button($dark-button-text-color, $button-backdrop-color);
        width: 200px;

    }
}