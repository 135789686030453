@import './../../../../../styles/variables.scss';

.go-back-button {
    @include button(#fff, #5D33B4);
    margin: 1rem 0 -1rem 1rem;
    width: 200px;
    &::before {
        content: '<';
        margin-right: 1rem;
        font-weight: bold;
    }
}